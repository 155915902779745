import request from '@/utils/request'

export function getOfferDataFromFirebase() {
    return request({
        url: '/dynamicoffers/getofferdata',
        method: 'get'
    })
}

export function addOfferDataToFirebase(formData) {
    return request({
        url: '/dynamicoffers/addofferdata',
        method: 'post',
        data: formData
    })
}

export function deleteOneOfferFromeFirebase(db_id) {
    return request({
        url: '/dynamicoffers/deleteoffer/' + db_id,
        method: 'delete'
    })
}

export function activateOneOfferFromeFirebase(db_id) {
    return request({
        url: '/dynamicoffers/activateoffer/' + db_id,
        method: 'get'
    })
}

export function deactivateOneOfferFromeFirebase(db_id) {
    return request({
        url: '/dynamicoffers/deactivateoffer/' + db_id,
        method: 'get'
    })
}