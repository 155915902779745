<template>
  <div class="app-container">
    <el-tabs v-model="activeTab" @tab-click="handleClick" v-loading="loading">
      <!-- Begin: Offer List -->
      <el-tab-pane label="Offer List" name="List">
        <el-table :data="allOffers" border style="width: 100%">
          <el-table-column label="Offer Name" width="250px">
            <template slot-scope="{ row }">
              <span>{{ row.offerName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Android PackageID" width="250px">
            <template slot-scope="{ row }">
              <span>{{ row.androidPackageID }}</span>
            </template>
          </el-table-column>

          <el-table-column label="iOS PackageID" width="250px">
            <template slot-scope="{ row }">
              <span>{{ row.iOSPackageID }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Price" width="150px">
            <template slot-scope="{ row }">
              <span>{{ row.price }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Price Old" width="150px">
            <template slot-scope="{ row }">
              <span>{{ row.priceOld }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="Rewards & Conditions"
            width="250px"
            type="expand"
          >
            <template slot-scope="{ row }">
              <el-collapse>
                <el-collapse-item title="Rewards" name="1">
                  <el-card class="box-card" style="min-height: 150px">
                    <el-table :data="row.rewards" border style="width: 100%">
                      <el-table-column label="Reward Name" width="250px">
                        <template slot-scope="{ row }">
                          <span>{{ row.name }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column label="Reward Code" width="250px">
                        <template slot-scope="{ row }">
                          <span>{{ row.code }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column label="Reward Value" width="250px">
                        <template slot-scope="{ row }">
                          <span>{{ row.value }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-card>
                </el-collapse-item>

                <el-collapse-item title="Conditions" name="2">
                  <el-card class="box-card" style="min-height: 150px">
                    <el-table :data="row.conditions" border style="width: 100%">
                      <el-table-column label="Condition Name" width="250px">
                        <template slot-scope="{ row }">
                          <span>{{ row.name }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column label="Condition Code" width="250px">
                        <template slot-scope="{ row }">
                          <span>{{ row.code }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column label="Condition Value" width="250px">
                        <template slot-scope="{ row }">
                          <span>{{ row.value }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-card>
                </el-collapse-item>
              </el-collapse>
            </template>
          </el-table-column>

          <el-table-column label="Action">
            <template slot-scope="{ row }">
              <template v-if="row.activated == 0">
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="onActivateOneOffer(row)"
                  >Activate</el-button
                >

                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDeleteOneOffer(row)"
                  >Delete</el-button
                >
              </template>
              <template v-else>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="onDeactivateOneOffer(row)"
                  >Stop!</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- End: Leaderboard Status -->

      <!-- Begin: Add new Leaderboard -->
      <el-tab-pane label="Add New" name="AddNew">
        <el-form label-width="200px">
          <el-form-item label="Offer Name" style="width: 40%">
            <el-input
              v-model="currentOfferInformation.offerName"
              class="edit-input"
              placeholder="Offer Name"
              @change="onChangeOfferName()"
            />
          </el-form-item>

          <template v-if="localizeString.length > 0">
            <el-form-item>
              {{ localizeString }}
            </el-form-item>
          </template>

          <el-form-item label="Android PackageID" style="width: 40%">
            <el-input
              v-model="currentOfferInformation.androidPackageID"
              class="edit-input"
              placeholder="zs.shop.pack.default"
            />
          </el-form-item>

          <el-form-item label="iOS PackageID" style="width: 40%">
            <el-input
              v-model="currentOfferInformation.iOSPackageID"
              class="edit-input"
              placeholder="zs.ios.shop.pack.default"
            />
          </el-form-item>

          <el-form-item label="Price" style="width: 40%">
            <el-input
              v-model="currentOfferInformation.price"
              class="edit-input"
              placeholder="0.99$"
            />
          </el-form-item>

          <el-form-item label="Price Old" style="width: 40%">
            <el-input
              v-model="currentOfferInformation.priceOld"
              class="edit-input"
              placeholder="1.99$"
            />
          </el-form-item>

          <el-collapse v-model="activeCollapseName">
            <el-collapse-item name="1">
              <template slot="title">
                <h2>Add Rewards</h2>
              </template>
              <el-card class="box-card" style="min-height: 150px">
                <div style="padding-left: 120px; padding-bottom: 20px">
                  <add-reward
                    v-on:CallbackChangeData="callbackChangeDataRewards"
                    v-model="currentOfferInformation.rewards"
                  />
                </div>

                <br />
                <br />

                <el-form-item label="Choose Main Reward">
                  <el-select
                    v-model="currentOfferInformation.mainRewardCode"
                    filterable
                    allow-create
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in currentOfferInformation.rewards"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-card>
            </el-collapse-item>
          </el-collapse>

          <el-collapse v-model="activeCollapseName">
            <el-collapse-item name="2">
              <template slot="title">
                <h2>Add User Conditions</h2>
              </template>
              <div style="padding-left: 120px; padding-bottom: 20px">
                <add-user-condition
                  v-on:callbackChangeDataUserConditions="
                    callbackChangeDataUserConditions
                  "
                  v-model="currentOfferInformation.conditions"
                />
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form>

        <br />
        <br />

        <el-row>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit-outline"
            @click="onAddNewOffer()"
            >Add New Offer</el-button
          >

          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-edit-outline"
            @click="ExportOffer()"
            >Export Offer</el-button
          > -->
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import { Message, MessageBox } from "element-ui";
import AddReward from "@/components/AddReward";
import AddUserCondition from "@/components/AddUserCondition";

const offerAPI = require("@/api/dynamic_offer");
var fs = require("fs");

export default {
  name: "DynamicOffers",
  components: { AddReward, AddUserCondition },
  data() {
    return {
      loading: false,
      currentOfferInformation: {
        offerName: "",
        androidPackageID: "",
        iOSPackageID: "",
        price: undefined,
        priceOld: undefined,
        rewards: [],
        mainRewardCode: undefined,
        conditions: [],
        activated: 0,
      },
      allOffers: [],
      activeCollapseName: [],
      rewardList: {},
      activeTab: "List",
      localizeString: "",
    };
  },
  created() {
    this.loading = false;
    this.currentOfferInformation = {
      offerName: "",
      androidPackageID: "",
      iOSPackageID: "",
      price: undefined,
      priceOld: undefined,
      rewards: [],
      mainRewardCode: undefined,
      conditions: [],
      activated: 0,
    };

    this.onGetAllOffers();
  },
  methods: {
    // ========================================
    callbackChangeDataRewards(pickedRewards) {
      //do nothing but need
    },

    // ========================================
    callbackChangeDataUserConditions(pickedConditions) {
      //do nothing but need
    },

    // ========================================
    onGetAllOffers() {
      offerAPI
        .getOfferDataFromFirebase()
        .then((response) => {
          this.allOffers = response;
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Can't load offers " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    // ========================================
    onChangeOfferName() {
      if (this.currentOfferInformation.offerName.length > 0) {
        let temp = this.currentOfferInformation.offerName.replace(" ", "_");
        if (temp === this.currentOfferInformation.offerName) {
          this.localizeString = `Localize Name: "${this.currentOfferInformation.offerName}"`;
        } else {
          this.localizeString = `Localize Name: "${this.currentOfferInformation.offerName}" or "${temp}"`;
        }
      } else {
        this.localizeString = "";
      }
    },

    // ========================================
    onAddNewOffer() {
      console.log(this.currentOfferInformation);

      // missing offerName
      if (this.currentOfferInformation.offerName.length <= 0) {
        Message({
          message: "Missing OfferName!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // missing androidPackageID
      if (this.currentOfferInformation.androidPackageID.length <= 0) {
        Message({
          message: "Missing androidPackageID!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // missing iOSPackageID
      if (this.currentOfferInformation.iOSPackageID.length <= 0) {
        Message({
          message: "Missing iOSPackageID!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // missing price
      if (
        this.currentOfferInformation.price === undefined ||
        this.currentOfferInformation.price <= 0
      ) {
        Message({
          message: "Missing price!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // missing priceOld
      if (
        this.currentOfferInformation.priceOld === undefined ||
        this.currentOfferInformation.priceOld <= 0
      ) {
        Message({
          message: "Missing priceOld!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // missing rewards
      if (
        this.currentOfferInformation.rewards === undefined ||
        this.currentOfferInformation.rewards.length <= 0
      ) {
        Message({
          message: "Missing rewards!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // missing mainRewardCode
      if (
        this.currentOfferInformation.mainRewardCode === undefined ||
        this.currentOfferInformation.mainRewardCode.length <= 0
      ) {
        Message({
          message: "Missing mainRewardCode!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // // missing conditions
      // if (this.currentOfferInformation.conditions === undefined ||
      //     this.currentOfferInformation.conditions.length <= 0
      // ) {
      //   Message({
      //     message: "Missing conditions!",
      //     type: "error",
      //     duration: 5 * 1000,
      //   });

      //   return;
      // }

      this.loading = true;
      // process
      offerAPI
        .addOfferDataToFirebase(this.currentOfferInformation)
        .then((response) => {
          this.onGetAllOffers();

          this.currentOfferInformation = {
            offerName: "",
            androidPackageID: "",
            iOSPackageID: "",
            price: undefined,
            priceOld: undefined,
            rewards: [],
            mainRewardCode: "",
            conditions: [],
            activated: 0,
          };

          Message({
            message: "Add New Offer Success!",
            type: "success",
            duration: 5 * 1000,
          });

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Can't add new offer " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    // ========================================
    onActivateOneOffer(row) {
      MessageBox.confirm(
        "Do you confirm ACTIVATE offerName: " + row.offerName + "?",
        "Confirm ACTIVATE",
        {
          confirmButtonText: "Activate",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        offerAPI
          .activateOneOfferFromeFirebase(row.db_id)
          .then((response) => {
            this.loading = false;
            this.onGetAllOffers();
            Message({
              message: "Activate Offer Success!",
              type: "success",
              duration: 5 * 1000,
            });
          })
          .catch((error) => {
            this.loading = false;
            Message({
              message: "Activate Offer Error: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },

    // ========================================
    onDeactivateOneOffer(row) {
      MessageBox.confirm(
        "Do you confirm DEACTIVATE offerName: " + row.offerName + "?",
        "Confirm DEACTIVATE",
        {
          confirmButtonText: "Deactivate",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        offerAPI
          .deactivateOneOfferFromeFirebase(row.db_id)
          .then((response) => {
            this.loading = false;
            this.onGetAllOffers();
            Message({
              message: "Deactivate Offer Success!",
              type: "success",
              duration: 5 * 1000,
            });
          })
          .catch((error) => {
            this.loading = false;
            Message({
              message: "Deactivate Offer Error: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },

    // ========================================
    onDeleteOneOffer(row) {
      MessageBox.confirm(
        "Do you confirm DELETE offerName: " + row.offerName + "?",
        "Confirm DELETE",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        offerAPI
          .deleteOneOfferFromeFirebase(row.db_id)
          .then((response) => {
            this.loading = false;
            this.onGetAllOffers();
            Message({
              message: "Delete Offer Success!",
              type: "success",
              duration: 5 * 1000,
            });
          })
          .catch((error) => {
            this.loading = false;
            Message({
              message: "Delete Offer Error: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },

    // ========================================
    ExportOffer() {},

    // ========================================
    // OTHERS FUNCTIONS
    handleClick(tab, event) {},
  },
};
</script>