<template>
  <div>
    <el-transfer
      v-model="pickCondition"
      filterable
      :data="data"
      :titles="['Full Conditions', 'Used Conditions']"
      :button-texts="['Remove', 'Add']"
      @change="handleChange"
      style="padding-bottom:30px"
      v-loading="loading"
    ></el-transfer>
    <el-card style="width:800px">
      <el-table :data="tableData">
        <el-table-column label="Name" width="350px">
          <template slot-scope="{row}">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Value">
          <template slot-scope="{row}">
            <el-input 
            class="edit-input"
            v-model="row.value" 
            size="small" 
            @change="handleChange"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<style>
  .el-transfer-panel {
    width: 500px;
  }
</style>

<script>
import { Message } from "element-ui";
import { getAllUserConditions } from "@/api/userconditions";

export default {
  name: "AddUserCondition",
  components: {},
  data() {
    return {
      data: [],
      pickCondition: [],
      conditions: [],
      dictionaryData: {},
      tableData: [],
      loading: true,

    };
  },
  created() {
    this.$emit("input", this.conditions);
    this.getListUserConditions();
  },
  methods: {
    getListUserConditions() {
      getAllUserConditions()
        .then(response => {
          response.forEach(i => {
            this.data.push({
              label: i.name,
              key: i.code
            });
          });
          this.loading = false;
        })
        .catch(error => {
          Message({
            message: "Can't fetch list user conditions (1):" + error,
            type: "error",
            duration: 5 * 1000
          });
          this.loading = false;
        });
    },
    handleChange() {
      // clear current data
      this.tableData = [];

      // generate tab data
      this.pickCondition.forEach(item => {
        if (this.dictionaryData[item] === undefined) {
          let conditionName = this.data.find(function(element) {
            return element.key === item;
          });
          this.dictionaryData[item] = {
            name: conditionName.label,
            code: item,
            value: 0
          };
        }

        // transfer to table
        this.tableData.push(this.dictionaryData[item]);
      });

      // generate reward string
      this.conditions = [];
      this.tableData.forEach(item => {
        this.conditions.push(item);
      });

      this.$emit("input", this.conditions);

      this.$emit("CallbackChangeDataUserConditions", {
        conditions: this.conditions
      });
    }
  }
};
</script>